<template>
  <div class="information">
    <div class="information-empty" v-if="totalNum == 0">
      <div class="information-empty-div">
        <img
          class="information-empty-img"
          src="../assets/img_zwsj.png"
          alt=""
          srcset=""
        />
        <div class="information-empty-title">暂无数据</div>
      </div>
    </div>
    <div v-if="examineIS == false">
      <div class="information-block">
        <div
          class="information-block-list"
          v-for="(item, index) in informationObj.informationList"
          :key="index"
          @click="examineBtn(item.userInformationId)"
        >
          <div class="block-list-div">
            <div class="information-block-list-left">
              <div class="information-block1">
                <img
                  class="information-block-list-left-img"
                  :src="
                    item.isRead == 1
                      ? require('../assets/home/small.png')
                      : require('../assets/home/smallActive.png')
                  "
                />
                <div class="information-block-list-left-title">
                  {{ item.informationTitle }}
                </div>
              </div>

              <div class="information-block2-time">{{ item.createTime }}</div>
            </div>
          </div>
        </div>
      </div>

      <!--第四模块  分页模块-->
      <div class="headline-paging" v-if="totalNum > 10">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="10"
          layout="prev, pager, next, sizes, jumper"
          :total="totalNum"
          :pager-count="11"
        >
        </el-pagination>
      </div>
    </div>

    <div v-else>
      <div class="information-details">
        <div class="information-details-title">
          {{ informationDetails.informationTitle }}
        </div>
        <div
          class="information-details-img"
          v-for="(item, index) in informationImg"
          :key="index"
        >
          <img style="width: 100%; height: 100%" :src="item" alt="" />
        </div>
        <div
          class="information-details-text"
          v-html="informationDetails.text"
        ></div>

        <div class="information-details-addresser">
          发件人: {{ informationDetails.adminName }}
        </div>
        <div class="information-details-time">
          {{ informationDetails.createTime }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getInformation, getInformationShow } from '../api/index' // 消息列表接口  、  消息详情接口

export default {
  data() {
    return {
      // 控制页面显示
      examineIS: false,
      // 页数
      page: 1,
      // 页码
      limit: 10,
      // 消息通知对象存储
      informationObj: {},
      // 消息通知总数
      totalNum: 0,
      // 获取消息通知详情
      informationDetails: {},
      // 富文本解析
      informationImg: [],
    }
  },
  created() {
    // 获取消息列表的数据
    this.getInformation()
  },
  methods: {
    // 获取消息列表接口数据
    getInformation() {
      let params = {
        pageSize: this.limit,
        page: this.page,
      }
      getInformation(params).then((res) => {
        if (res.data.code == 200) {
          // 总共数组
          this.informationObj = res.data.content
          // 总共条数
          this.totalNum = Number(res.data.content.totalNum)
          this.$store.commit('isReadBtn', res.data.content.isRead)
        } else {
          // 退出登陆 清除用户资料
          localStorage.clear()
          // 重设路由
          resetRouter()
        }
      })
    },
    // 点击查看消息详情
    examineBtn(id) {
      this.examineIS = true
      // 获取对应的消息记录id
      this.getInformationShow(id)
    },
    // 获取对应的消息详情接口
    getInformationShow(id) {
      let params = {
        userInformationId: id,
      }
      getInformationShow(params).then((res) => {
        if (res.data.code == 200) {
          this.informationDetails = res.data.content

          this.informationImg = this.getImgSrc(this.informationDetails.content)

          var re = new RegExp('<[^<>]+>', 'g')
          var text = this.informationDetails.content.replace(re, '')

          this.informationDetails.text = text
        } else {
          // 退出登陆 清除用户资料
          localStorage.clear()
          // 重设路由
          resetRouter()
        }
      })
    },
    // 富文本数据截取图片数组
    getImgSrc(richtext) {
      let imgList = []
      richtext.replace(
        /<img [^>]*src=['"]([^'"]+)[^>]*>/g,
        (match, capture) => {
          imgList.push(capture)
        }
      )
      return imgList
    },
    // 获取当前每页条数
    handleSizeChange(val) {
      this.page = 1
      this.pageSize = val
      this.getInformation()
    },
    // 获取当前页码
    handleCurrentChange(val) {
      this.page = val
      this.getInformation()
    },
  },
}
</script>

<style lang="less" scoped>
.information {
  margin: 20px;
  background: #fff;
  .information-block {
    padding: 24px;
    box-sizing: border-box;
    .information-block-list {
      width: 100%;
      height: 82px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #fafafa;
      cursor: pointer;

      .block-list-div {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        .information-block-list-left {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .information-block1 {
            display: flex;
            align-items: center;

            .information-block-list-left-img {
              width: 14px;
              height: 14px;
            }
            .information-block-list-left-title {
              margin-left: 9px;
              font-size: 14px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #222222;
            }
          }

          .information-block2-time {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
  }

  .headline-paging {
    height: 102px;
    width: 100%;
    background: #ffffff;
    padding-right: 30px;

    /deep/ .el-pagination {
      display: flex;
      justify-content: flex-end;
      padding-top: 20px;
    }

    /deep/ .el-pager li {
      width: 32px;
      height: 32px;
      padding: 0;
      line-height: 32px;
      margin-right: 8px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
    }

    /deep/ .el-pager li.active {
      background: #eafbed;
      border-radius: 4px;
      color: #29d647;
    }

    /deep/ .el-pager li:hover {
      color: #29d647;
    }

    /deep/ .el-pagination .btn-prev {
      width: 32px;
      height: 32px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      margin-right: 8px;
    }

    /deep/.el-pagination .btn-next {
      width: 32px;
      height: 32px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      margin-right: 8px;
    }

    /deep/ .el-pagination button:hover {
      color: #29d647;
    }
  }

  .information-details {
    padding: 40px 24px;
    box-sizing: border-box;
    text-align: center;

    .information-details-title {
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
    }

    .information-details-img {
      width: 573px;
      height: 299px;
      margin: 0 auto;
      margin-top: 62px;
    }

    .information-details-text {
      text-align: left;
      margin-top: 22px;
      text-indent: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 500;
      color: #222222;
      line-height: 26px;
    }

    .information-details-addresser {
      margin-top: 150px;
      text-align: right;
      font-size: 22px;
      font-weight: 600;
      color: #222222;
      margin-right: 100px;
    }

    .information-details-time {
      text-align: right;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 600;
      color: #999999;
      margin-top: 6px;
      height: 20px;
      line-height: 20px;
      margin-right: 100px;
    }
  }

  .information-empty {
    width: 100%;
    height: 68vh;
    display: flex;
    justify-content: center;

    .information-empty-div {
      margin-top: 10%;
      text-align: center;
      .information-empty-img {
        width: 234px;
        height: 140px;
      }

      .information-empty-title {
        margin-top: 32px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #888888;
      }
    }
  }
}
</style>
